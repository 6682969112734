import React from "react"

const Cart = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.12 22C19.9352 22 21 20.9824 21 18.9667V8.83953C21 6.82387 19.9251 5.80626 17.8158 5.80626H16.1932C16.1425 3.75147 14.2868 2 11.9949 2C9.7031 2 7.84732 3.75147 7.79662 5.80626H6.18423C4.06479 5.80626 3 6.81409 3 8.83953V18.9667C3 20.9922 4.06479 22 6.18423 22H18.12ZM14.5606 5.80626H9.4293C9.48 4.52446 10.5651 3.48728 11.9949 3.48728C13.4248 3.48728 14.5099 4.52446 14.5606 5.80626ZM18.0896 20.4247H6.20451C5.19042 20.4247 4.63268 19.9061 4.63268 18.8885V8.91781C4.63268 7.9002 5.19042 7.3816 6.20451 7.3816H17.7854C18.7893 7.3816 19.3673 7.9002 19.3673 8.91781V18.8885C19.3673 19.9061 18.7893 20.4247 18.0896 20.4247Z" fill="#1A1A1A" />
        </svg>)
}

export default Cart;
