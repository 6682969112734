export const faqInfo = [
    {
        title: 'Стоимость доставки',
        text: 'Доставка осуществляется по всему миру. Доставка курьером от 399 р. Доставка в пункт выдачи от 199 р',
    },
    {
        title: 'Оплата заказа',
        text: 'Оплата осуществляется безналичным способом в момент заказа',
    },
    {
        title: 'Срок доставки',
        text: 'Срок доставки каждого товара зависит от того, с какого склада производится доставка товара и адреса доставки. Максимальный срок 30 дней',
    },
    {
        title: 'Отмена заказа',
        text: 'Сразу после оформления мы передаем заказ на формирование и доставку. Отказаться от товара вы сможете посредством направления заявления об отмене заказа на E-mail адрес, указанные в разделе «Контакты» официального сайта.',
    },
];

export const filters = [
    { filter: 'popular', title: 'Популярные' },
    { filter: 'new', title: 'Новинки' },
    { filter: 'cheap', title: 'Сначала дешёвые' },
    { filter: 'costly', title: 'Сначала дорогие' },
    { filter: 'topRate', title: 'По рейтингу' },
    { filter: 'reviews', title: 'По количеству отзывов' },
    { filter: 'sale', title: 'По скидке' },
];
