import React from "react"

const LogoInstagram = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.66498 12C9.66498 10.7109 10.7101 9.66599 11.9993 9.66599C13.2885 9.66599 14.3336 10.7109 14.3336 12C14.3336 13.289 13.2885 14.3339 11.9993 14.3339C10.7101 14.3339 9.66498 13.289 9.66498 12Z" fill="#1A1A1A" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.17144 6.30202C9.9098 6.2684 10.131 6.26118 12.0004 6.26118L11.9993 6.26229L12.6776 6.26274C13.9386 6.26475 14.1906 6.2741 14.8283 6.30313C15.5108 6.33425 15.8815 6.44817 16.1283 6.54403C16.4327 6.65611 16.7081 6.8351 16.9342 7.06779C17.1669 7.2938 17.3459 7.56916 17.458 7.87356C17.5539 8.12029 17.6678 8.49095 17.6989 9.17336C17.728 9.81094 17.7373 10.0629 17.7393 11.3237V12.6801C17.7373 13.941 17.728 14.1929 17.6989 14.8305C17.6678 15.5129 17.5539 15.8835 17.458 16.1303C17.2222 16.7405 16.7397 17.2229 16.1294 17.4587C15.8826 17.5545 15.5119 17.6685 14.8294 17.6996C14.1584 17.7301 13.9143 17.7389 12.471 17.7402H11.5298C10.0865 17.7389 9.84243 17.7301 9.17144 17.6996C8.48893 17.6685 8.11821 17.5545 7.87144 17.4587C7.567 17.3466 7.29159 17.1676 7.06555 16.9349C6.83283 16.7089 6.65382 16.4335 6.54172 16.1291C6.44584 15.8824 6.33191 15.5118 6.30078 14.8293C6.26868 14.1246 6.26064 13.8911 6.25998 12.2457L6.25993 12.0008C6.25993 10.1317 6.26716 9.9105 6.30078 9.17225C6.33191 8.48984 6.44584 8.11918 6.54172 7.87245C6.65382 7.56805 6.83283 7.29269 7.06555 7.06667C7.29159 6.83399 7.567 6.655 7.87144 6.54292C8.11821 6.44706 8.48893 6.33314 9.17144 6.30202ZM14.5415 9.45905C13.8673 8.78497 12.9528 8.40634 11.9993 8.40648C10.5452 8.40648 9.23425 9.28231 8.6778 10.6255C8.12136 11.9688 8.429 13.5149 9.45726 14.5429C10.4855 15.571 12.0319 15.8784 13.3753 15.322C14.7187 14.7655 15.5945 13.4547 15.5944 12.0008C15.5945 11.0475 15.2157 10.1331 14.5415 9.45905ZM16.5765 8.26422C16.5765 7.80033 16.2004 7.42427 15.7364 7.42427C15.2725 7.42427 14.8964 7.80033 14.8964 8.26422C14.8964 8.72812 15.2725 9.10417 15.7364 9.10417C16.2004 9.10417 16.5765 8.72812 16.5765 8.26422Z" fill="#1A1A1A" />
            <path fillRule="evenodd" clipRule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM14.8858 5.04334C14.1408 5.00945 13.9007 5.00111 12.0004 5L11.1152 5.00091C9.99554 5.00379 9.72485 5.01427 9.11391 5.04223C8.36721 5.07641 7.85894 5.19561 7.41347 5.36871C6.94638 5.54444 6.52323 5.81989 6.17351 6.17588C5.81797 6.52568 5.54296 6.94876 5.36761 7.41566C5.19309 7.86106 5.07471 8.36981 5.04081 9.11473C5.00998 9.79194 5.00029 10.0519 4.99879 11.5219L4.99878 12.4797C5.00025 13.9494 5.00973 14.2081 5.04081 14.8869C5.07499 15.6335 5.19309 16.1405 5.36622 16.5859C5.54198 17.053 5.81747 17.476 6.17351 17.8257C6.52329 18.1817 6.94642 18.4572 7.41347 18.6332C7.85811 18.806 8.36777 18.9243 9.1128 18.9585C9.75624 18.988 10.0231 18.9979 11.3086 19L12.4783 19.0003C13.9482 18.9988 14.207 18.9893 14.8858 18.9585C15.6325 18.9246 16.1397 18.806 16.5851 18.6332C17.5255 18.2694 18.2689 17.5261 18.6327 16.5859C18.8055 16.1414 18.9239 15.6318 18.9581 14.8869C18.9922 14.1419 19 13.9019 19 12.0008C19 10.0997 18.992 9.86132 18.9581 9.11473C18.9242 8.36814 18.8055 7.86106 18.6327 7.41566C18.4567 6.94867 18.1811 6.52561 17.8251 6.17588C17.4752 5.82039 17.0521 5.54542 16.5851 5.3701C16.1397 5.19561 15.6308 5.07724 14.8858 5.04334Z" fill="#1A1A1A" />
        </svg>
    )
}

export default LogoInstagram;