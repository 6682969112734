import React from "react"

const LogoViber = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2467_358)">
                <path d="M23.1551 13.893C23.8711 7.86602 22.8111 4.06102 20.8991 2.34002L20.9001 2.33902C17.8141 -0.599975 7.39208 -1.03498 3.70008 2.47102C2.04208 4.18602 1.45808 6.70302 1.39408 9.81903C1.33008 12.936 1.25408 18.775 6.69508 20.359H6.70008L6.69508 22.778C6.69508 22.778 6.65808 23.758 7.28408 23.955C8.00008 24.187 8.32408 23.732 10.5511 21.072C14.2751 21.395 17.1351 20.655 17.4601 20.547C18.2121 20.295 22.4671 19.732 23.1551 13.893ZM10.9181 19.37C10.9181 19.37 8.56108 22.309 7.82808 23.072C7.58808 23.32 7.32508 23.297 7.32908 22.805C7.32908 22.482 7.34708 18.789 7.34708 18.789C2.73408 17.467 3.00608 12.495 3.05608 9.89402C3.10608 7.29202 3.58208 5.16102 4.98608 3.72602C8.22508 0.689025 17.3621 1.36802 19.6901 3.55602C22.5361 6.07902 21.5231 13.207 21.5291 13.45C20.9441 18.324 17.4961 18.633 16.8621 18.844C16.5911 18.934 14.0761 19.581 10.9181 19.37Z" fill="#1A1A1A" />
                <path d="M12.2214 4.29639C11.8364 4.29639 11.8364 4.89639 12.2214 4.90139C15.2084 4.92439 17.6684 7.00639 17.6954 10.8254C17.6954 11.2284 18.2854 11.2234 18.2804 10.8204H18.2794C18.2474 6.70539 15.5614 4.31939 12.2214 4.29639Z" fill="#1A1A1A" />
                <path d="M16.1519 10.193C16.1429 10.591 16.7319 10.61 16.7369 10.207C16.7859 7.93804 15.3869 6.06904 12.7579 5.87204C12.3729 5.84404 12.3329 6.44904 12.7169 6.47704C14.9969 6.65004 16.1979 8.20604 16.1519 10.193Z" fill="#1A1A1A" />
                <path d="M15.5202 12.7746C15.0262 12.4886 14.5232 12.6666 14.3152 12.9476L13.8802 13.5106C13.6592 13.7966 13.2462 13.7586 13.2462 13.7586C10.2322 12.9616 9.42624 9.80759 9.42624 9.80759C9.42624 9.80759 9.38924 9.38059 9.66524 9.15159L10.2092 8.70159C10.4812 8.48559 10.6532 7.96559 10.3762 7.45459C9.63624 6.11759 9.13924 5.65659 8.88624 5.30259C8.62024 4.96959 8.22024 4.89459 7.80424 5.11959H7.79524C6.93024 5.62559 5.98324 6.57259 6.28624 7.54759C6.80324 8.57559 7.75324 11.8526 10.7812 14.3286C12.2042 15.4996 14.4562 16.6996 15.4122 16.9766L15.4212 16.9906C16.3632 17.3046 17.2792 16.3206 17.7682 15.4296V15.4226C17.9852 14.9916 17.9132 14.5836 17.5962 14.3166C17.0342 13.7686 16.1862 13.1636 15.5202 12.7746Z" fill="#1A1A1A" />
                <path d="M13.1684 8.10417C14.1294 8.16017 14.5954 8.66217 14.6454 9.69317C14.6634 10.0962 15.2484 10.0682 15.2304 9.66517C15.1664 8.31917 14.4644 7.56917 13.2004 7.49917C12.8154 7.47617 12.7794 8.08117 13.1684 8.10417Z" fill="#1A1A1A" />
            </g>
            <defs>
                <clipPath id="clip0_2467_358">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LogoViber;