import React from "react"

const LogoWA = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2467_353)">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.997 0H12.003C18.6195 0 24 5.3835 24 12C24 18.6165 18.6195 24 12.003 24C9.5625 24 7.299 23.274 5.4015 22.017L0.789 23.4915L2.2845 19.0335C0.846 17.058 0 14.625 0 12C0 5.382 5.3805 0 11.997 0ZM16.6298 18.6393C17.5463 18.4413 18.6953 17.7633 18.9848 16.9458C19.2743 16.1268 19.2743 15.4293 19.1903 15.2808C19.1225 15.1629 18.9621 15.0857 18.7227 14.9704C18.6604 14.9404 18.5927 14.9078 18.5198 14.8713C18.1673 14.6958 16.4528 13.8483 16.1288 13.7358C15.8108 13.6158 15.5078 13.6578 15.2678 13.9968C15.2222 14.0607 15.1767 14.1247 15.1314 14.1884C14.8418 14.5956 14.5625 14.9884 14.3303 15.2388C14.1188 15.4638 13.7738 15.4923 13.4843 15.3723C13.4528 15.3591 13.4166 15.3445 13.3761 15.3281C12.9177 15.143 11.901 14.7324 10.6703 13.6368C9.63234 12.7128 8.92734 11.5623 8.72334 11.2173C8.52305 10.8712 8.69582 10.6682 8.85549 10.4807C8.85845 10.4772 8.8614 10.4737 8.86434 10.4703C8.96729 10.3429 9.06719 10.2368 9.16759 10.1302C9.23981 10.0536 9.31229 9.97662 9.38634 9.89127C9.39734 9.87859 9.40804 9.86629 9.41846 9.85432C9.5757 9.67352 9.66933 9.56586 9.77484 9.34077C9.89484 9.10827 9.80934 8.86827 9.72384 8.69127C9.66502 8.56731 9.30163 7.68563 8.98967 6.92874C8.85618 6.60484 8.7321 6.3038 8.64534 6.09477C8.41284 5.53827 8.23584 5.51727 7.88334 5.50227C7.87254 5.50173 7.86163 5.50118 7.85062 5.50062C7.73919 5.49498 7.61648 5.48877 7.48134 5.48877C7.02234 5.48877 6.54384 5.62377 6.25434 5.91927C6.24423 5.9296 6.23368 5.94033 6.22274 5.95146C5.85237 6.32812 5.02734 7.16719 5.02734 8.83977C5.02734 10.5156 6.2164 12.1374 6.43641 12.4375C6.44247 12.4458 6.4478 12.453 6.45234 12.4593C6.46558 12.4767 6.49053 12.5128 6.52685 12.5654C6.97607 13.2159 9.16409 16.3845 12.4268 17.7363C15.1838 18.8793 16.0028 18.7728 16.6298 18.6393Z" fill="#1A1A1A" />
            </g>
            <defs>
                <clipPath id="clip0_2467_353">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LogoWA;