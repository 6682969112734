import React from "react"

const LogoVK = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM18.2109 8.01423C18.6706 8.01423 18.7743 8.24199 18.6706 8.55516C18.5086 9.27288 17.1635 11.1767 16.7469 11.7664C16.6677 11.8785 16.6221 11.9431 16.6244 11.9431C16.4613 12.1993 16.402 12.3132 16.6244 12.5979C16.7048 12.7031 16.8753 12.8637 17.0701 13.0473C17.2705 13.2361 17.4967 13.4492 17.6771 13.6512C18.3295 14.363 18.8337 14.9609 18.9671 15.3737C19.0857 15.7865 18.8781 16 18.4333 16H16.9061C16.5014 16 16.2928 15.7769 15.8482 15.3013C15.6576 15.0974 15.4235 14.8471 15.1121 14.548C14.2076 13.7082 13.8073 13.5943 13.5849 13.5943C13.2735 13.5943 13.1845 13.6655 13.1845 14.0925V15.4164C13.1845 15.7722 13.0659 15.9858 12.0873 15.9858C10.4712 15.9858 8.67711 15.0463 7.41681 13.2954C5.51895 10.7331 5 8.79715 5 8.41281C5 8.19929 5.07414 8 5.51895 8H7.06096C7.44647 8 7.59474 8.15658 7.74301 8.5694C8.49919 10.6619 9.75949 12.4982 10.2784 12.4982C10.4712 12.4982 10.5601 12.4128 10.5601 11.9431V9.77936C10.5235 9.16435 10.3005 8.89639 10.1352 8.69769C10.0326 8.57446 9.95224 8.47786 9.95224 8.34164C9.95224 8.17082 10.1005 8 10.3377 8H12.7397C13.0659 8 13.1845 8.17082 13.1845 8.54093V11.4591C13.1845 11.7722 13.318 11.8861 13.4218 11.8861C13.6145 11.8861 13.7776 11.7722 14.1335 11.4306C15.2307 10.2491 16.0165 8.42705 16.0165 8.42705C16.1203 8.21352 16.2982 8.01423 16.6837 8.01423H18.2109Z" fill="#1A1A1A" />
        </svg>
    )
}

export default LogoVK;